import * as React from 'react'
import {Link} from "gatsby";
import {useEffect, useState} from "react";
import {inTime} from "../../logic/dateComparisons";
export default function CurrentStatus({store}) {
    const [currentTime, setCurrentTime] = useState(Date.now())
    useEffect(() => {
        setCurrentTime(Date.now())
    }, [])
    return (

        <div id="currentStatus">
            <div id="staticStatus">
                { inTime(store.lastOrderTime, currentTime)
                    ? <div className="alert alert-success" role="alert">
                        Ihre Bestellung wird voraussichtlich noch vor Weihnachten ankommen.
                    </div>
                    : <div className="alert alert-danger" role="alert">
                        Ihre Bestellung wird voraussichtlich nicht mehr rechtzeitig ankommen.
                    </div>}
            </div>


        </div>
    )
}